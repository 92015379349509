import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"access-control-expose-headers":"*","access-control-allow-origin":"*","server":"cloudflare","timing-allow-origin":"*","cf-ray":"8f1cff0c4a6efb3c-SJC","date":"Sat, 14 Dec 2024 08:59:10 GMT","x-served-by":"cache-fra-etou8220096-FRA, cache-lga21960-LGA","accept-ranges":"bytes","report-to":"{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v4?s=rzg7KKXjH7CcWbk1ZDYbXuTtVGPBnKl4Wfbd5XwTEQ4yVPlO0kezx1jlcunpfi9o21w7gd85Fy32D0uQ9u6fRiu17YOp5RB0L4Pj54QFftn3bUReHtyfCXITWfrF%2Fst7SPE%3D\"}],\"group\":\"cf-nel\",\"max_age\":604800}","alt-svc":"h3=\":443\"; ma=86400","nel":"{\"success_fraction\":0.01,\"report_to\":\"cf-nel\",\"max_age\":604800}","x-content-type-options":"nosniff","cross-origin-resource-policy":"cross-origin","x-cache":"HIT, HIT","content-type":"text/plain; charset=utf-8","strict-transport-security":"max-age=31536000; includeSubDomains; preload","x-jsd-version-type":"version","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","vary":"Accept-Encoding","cf-cache-status":"HIT","age":"1145","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","x-jsd-version":"1.0.8"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1734166750}